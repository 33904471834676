import React from "react"
import PropTypes from "prop-types"
import Styles from "./image.module.scss"
import Img from "gatsby-image"

const Image = ({ ...props }) => {
  const isStatic = props.static
  const splitPublicUrl = props?.sharp?.publicURL?.split(".")
  const isGif =
    splitPublicUrl && splitPublicUrl[splitPublicUrl.length - 1] === "gif"

  return (
    <>
      {isStatic && (
        <img
          className={Styles.image}
          alt={props.alt}
          src={props.src}
          loading="lazy"
        />
      )}

      {!isStatic && props.sharp && props.sharp.childImageSharp && (
        <Img alt={props.alt} fluid={props.sharp.childImageSharp.fluid} />
      )}

      {isGif && <img alt={props.alt} src={props?.sharp?.publicURL} />}
    </>
  )
}

Image.propTypes = {
  alt: PropTypes.string,
}

Image.defaultProps = {
  alt: "CaptionHub",
}

export default Image
